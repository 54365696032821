import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, share } from 'rxjs/operators';
import { ProfileService } from '../profile/profile.service';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { Tab } from 'src/app/shared/model/tabs.model';
import { Menus, SubMenus, PatientMenu, PatientSubMenu } from 'src/app/shared/constants/roles.constant';

@Injectable({
  providedIn: 'root'
})
export class TabServiceService {
  public static allAccessControls={}; //contains all records fetched from SF
  public static portalAccessControls=[];
  private leadSelected = new BehaviorSubject<boolean>(false);
  constructor(private http?: HttpClient, private profileSvc?: ProfileService) { }

  getTabs() {
    const data = '/services/data/v50.0/query/?q='+`SELECT+Id,Name,KB_Category__c,Tabs_Access__c,Role__c+FROM+Portal_Access_Control__c`;
    const uri = environment.NODE_SERVICE_BASE_URL + environment.NODE_SERVICE_API_END_POINT + '/execute-query';
    return this.http.post(uri, data).pipe(map((data: any) => {
      TabServiceService.portalAccessControls = Object.assign([], data.records);
      TabServiceService.allAccessControls = data && data.records ? TabServiceService.createControls(data.records) : {};
      return TabServiceService.allAccessControls;
    }));
  }

  public getAllTabs() {
    return this.getTabs();
  }

  private static createControls(records) {
    const temp = {};
    records.forEach(element => {
      const listOfTabs: Array<any> = element && element.Tabs_Access__c ? element.Tabs_Access__c.split(';') : [];
      if(listOfTabs.length){
      let arr: Array<Tab> = [];
      let arrSubMenus: Array<string> = [];
      // prepare menus
      listOfTabs.forEach((x: string) => {
        if (x.includes("#")) {
          arrSubMenus.push(x);
        } else if(Menus[x]) { // if menus exists in list then only add
          arr.push(new Tab(this.processTab(x)));
        }
      });
      temp[element.Role__c] = arr;

      //extract sub menus
      arr.forEach((x) => {
        let res = arrSubMenus.filter(y => y.includes(x.name()+'#'));
        res.forEach((r) => {// if sub menu exists in list then only add
            const sm = r.substring(r.indexOf('#')+1);
            if (SubMenus[sm]) x.subMenus().push(new Tab(this.processSubTab(sm)));
        });
      });
      }
    });
    return temp;
  }

  public static getTabsByRole(role) {
    return TabServiceService.allAccessControls[role] ? TabServiceService.allAccessControls[role].sort((a,b) => { 
      if (a.name() === Menus.Dashboard) return -1;
      if (b.name() === Menus.Dashboard) return 1;
      if ( a.name() < b.name() ){
        return -1;
      }
      if ( a.name() > b.name() ){
        return 1;
      }
      return 0;
    }) : [];
  }

  leadTabSelected() {
    this.leadSelected.next(true);
  }

  leadTabSelectedObservable(): Observable<boolean> {
    return this.leadSelected.asObservable().pipe(share());
  }

  private static processTab(tabName) {
    switch (tabName) {
      case Menus.Dashboard:
        return this.prepareObject(Menus.Dashboard, 'fa fa-tachometer', './', 'active', []);
      case Menus.Appointments:
        return this.prepareObject(Menus.Appointments, 'fa fa-calendar', './appoinments', 'active', []);
     /*  case Menus.Access_Control:
        return this.prepareObject(Menus.Access_Control, 'accessibility', './access-control', 'active', []); */

      case Menus.Leads:
        return this.prepareObject('Back Leads', 'fa fa-user-plus', './leads', 'active', []);

     // case Menus.Missing_Forms:
     //   return this.prepareObject(Menus.Missing_Forms, 'missingforms', './missing-forms', 'active', []);

      case Menus.Open_Orders:
        return this.prepareObject(Menus.Open_Orders, 'fa fa-shopping-cart', './open-order', 'active', []);

      case Menus.Patients:
        return this.prepareObject(Menus.Patients, 'fa fa-medkit', './provider-patients', 'active', []);

      case Menus.Subscriptions:
        return this.prepareObject(Menus.Subscriptions, 'fa fa-ticket', './subscription', 'active', []);

      case Menus.Support_Tickets:
        return this.prepareObject(Menus.Support_Tickets, 'fa fa-ticket', './support-tickets', 'active', []);

      /* case Menus.Unsigned_Encounters:
        return this.prepareObject(Menus.Unsigned_Encounters, 'fa fa-newspaper-o', './unsigned-encounters', 'active', []);
 
      case Menus.Time_Off:
        return this.prepareObject('Schedule Change/Time Off', 'fa fa-calendar-check-o', './schedule-change', 'active', []);
*/
      case Menus.Office_Team:
        return this.prepareObject(Menus.Office_Team, 'fa fa-users', './provider', 'active', []);

      case Menus.Marketing:
        return this.prepareObject(Menus.Marketing, 'fa fa-newspaper-o', './marketing-event', 'active', []);

      case Menus.Admin:
        return this.prepareObject(Menus.Admin, 'fa fa-user-circle-o', './error-logs', 'active', []); 

      case Menus.Compliance:
          return this.prepareObject(Menus.Compliance, 'fa fa-lock', '', 'active', []);

      case Menus.Financial:
          return this.prepareObject(Menus.Financial, 'fa fa-usd', '', 'active', []);

      case Menus.Supplements:
          return this.prepareObject(Menus.Supplements, 'fa fa-medkit', '', 'active', []);

      case Menus.Labs:
          return this.prepareObject(Menus.Labs, 'fa fa-building', './', 'active', []);

      case Menus.Company:
          return this.prepareObject(Menus.Company, 'fa fa-hospital-o', './', 'active', []);

      case Menus.HR:
          return this.prepareObject(Menus.HR, 'fa fa-hospital-o', './', 'active', []);

      case Menus.Knowledge_Base:
          return this.prepareObject(Menus.Knowledge_Base, 'fa fa-book', '', 'active', []);

      case Menus.Survey:
          return this.prepareObject(Menus.Survey, 'fa fa-book', '', 'active', []);    
      
      case Menus.Emails:
          return this.prepareObject(Menus.Emails, 'fa fa-envelope', '', 'active', []);

      case Menus.Interactions:
          return this.prepareObject(Menus.Leads, 'fa fa-envelope', './interaction', 'active', []);
    }
  }

  private static processSubTab(tabName) {
    switch (tabName) {
      case SubMenus.All_Patients:
        return this.prepareObject(SubMenus.All_Patients, '', './provider-patients', 'active', []);
      case SubMenus.New_Patients:
        return this.prepareObject(SubMenus.New_Patients, '', './new-patients', 'active', []);
      case SubMenus.Patient_Visits:
        return this.prepareObject(SubMenus.Patient_Visits, '', './patient-visits', 'active', []);
      case SubMenus.Subscription_Contracts:
        return this.prepareObject(SubMenus.Subscription_Contracts, '', './subscription-contract', 'active', []);
      case SubMenus.Active_Subscriptions:
        return this.prepareObject(SubMenus.Active_Subscriptions, '', './subscription', 'active', []);
      case SubMenus.Upcoming_Appts:
        return this.prepareObject(SubMenus.Upcoming_Appts, '', './appoinments', 'active', []);
      case SubMenus.Past_Appts:
        return this.prepareObject(SubMenus.Past_Appts, '', './past-appoinments', 'active', []);
     /*  case SubMenus.Life_Events:
        return this.prepareObject(SubMenus.Life_Events, '', './life-event', 'active', []); */
      case SubMenus.Events:
        return this.prepareObject(SubMenus.Events, '', './marketing-event', 'active', []);
      case SubMenus.Payments:
        return this.prepareObject(SubMenus.Payments, '', './payments', 'active', []);
      case SubMenus.Subscriptions:
        return this.prepareObject(SubMenus.Subscriptions, '', './subscriptions-dashboard', 'active', []);
      case SubMenus.Subscription_Utilization:
        return this.prepareObject(SubMenus.Subscription_Utilization, '', './utilization', 'active', []);
      case SubMenus.Error_Logs:
        return this.prepareObject(SubMenus.Error_Logs, '', './error-logs', 'active', []);
      case SubMenus.Users:
        return this.prepareObject(SubMenus.Users, '', './add', 'active', []);
      case SubMenus.Login_History:
        return this.prepareObject(SubMenus.Login_History, '', './login-history', 'active', []);

      case SubMenus.Release_Plans:
        return this.prepareObject(SubMenus.Release_Plans, '', './releasePlan', 'active', []);
      case SubMenus.Alerts:
        return this.prepareObject(SubMenus.Alerts, '', './', 'active', []);
      case SubMenus.Prospective_Patient:
        return this.prepareObject(SubMenus.Prospective_Patient, '', './new-patient-dashboard', 'active', []);

      case SubMenus.Access_Control:
        return this.prepareObject(SubMenus.Access_Control, '', './access-control', 'active', []);    

      case SubMenus.Provider_Licenses:
        return this.prepareObject(SubMenus.Provider_Licenses, '', './provider-licences', 'active', []);

      case SubMenus.Performance:
        return this.prepareObject(SubMenus.Performance, '', './performance', 'active', []); 

      case SubMenus.Companies:
        return this.prepareObject(SubMenus.Companies, '', './companies', 'active', []); 

      case SubMenus.Stock_Supplements:
        return this.prepareObject(SubMenus.Stock_Supplements, '', './supplements-details', 'active', []); 
        
        case SubMenus.Patient_Labs:
        return this.prepareObject(SubMenus.Patient_Labs, '', './patient-labs', 'active', []);

        case SubMenus.Lab_Kits:
          return this.prepareObject(SubMenus.Lab_Kits, '', './labs-kits', 'active', []);

        case SubMenus.Supplement_Insights:
            return this.prepareObject(SubMenus.Supplement_Insights, '', './supplememnts-insights', 'active', []);

        case SubMenus.Missing_Forms:
            return this.prepareObject(SubMenus.Missing_Forms, '', './missing-forms', 'active', []);
        
        case SubMenus.Unsigned_Encounters:
            return this.prepareObject(SubMenus.Unsigned_Encounters, '', './unsigned-encounters', 'active', []);
        
        case SubMenus.Active_Patients:
            return this.prepareObject(SubMenus.Active_Patients, '', './activePatients', 'active', []);
        
        case SubMenus.Inactive_Patients:
            return this.prepareObject(SubMenus.Inactive_Patients, '', './inActivePatients', 'active', []);
        
        case SubMenus.Out_Of_State_Patients:
            return this.prepareObject(SubMenus.Out_Of_State_Patients, '', './outOfStatePatients', 'active', []);

        case SubMenus.Assets:
            return this.prepareObject(SubMenus.Assets, '', './assets', 'active', []);

        case SubMenus.VitaLiving_Products:
            return this.prepareObject(SubMenus.VitaLiving_Products, '', './vlProducts', 'active', []);

        case SubMenus.Warehouses:
          return this.prepareObject(SubMenus.Warehouses, '', './warehouses', 'active', []);

        case SubMenus.VitaLiving_Orders:
            return this.prepareObject(SubMenus.VitaLiving_Orders, '', './vlOrders', 'active', []);

        case SubMenus.Transactions:
            return this.prepareObject(SubMenus.Transactions, 'fa fa-exchange', './admin-transactions', 'active', []);

        case SubMenus.Ecommerce:
            return this.prepareObject(SubMenus.Ecommerce, 'fa fa-exchange', './ecommerce-dashboard', 'active', []);

        case SubMenus.KPI:
            return this.prepareObject(SubMenus.KPI, 'fa fa-exchange', './office-user-dashboard', 'active', []);

        case SubMenus.Rep_Visits:
            return this.prepareObject(SubMenus.Rep_Visits, 'fa fa-exchange', './rep-visits', 'active', []);

        case SubMenus.Health_Coaching:
            return this.prepareObject(SubMenus.Health_Coaching, 'fa fa-exchange', './health-coach', 'active', []);
        
        case SubMenus.Sales_by_Brand:
            return this.prepareObject(SubMenus.Sales_by_Brand, 'fa fa-exchange', './vlsales', 'active', []);
        case SubMenus.Sales_by_Warehouse:
            return this.prepareObject(SubMenus.Sales_by_Warehouse, 'fa fa-exchange', './vlwarehouse', 'active', []);

        case SubMenus.Health_Coach:
            return this.prepareObject(SubMenus.Health_Coaching, 'fa fa-exchange', './health-coach-dashboard', 'active', []);

        case SubMenus.Lab_Invoices:
            return this.prepareObject(SubMenus.Lab_Invoices, 'fa fa-exchange', './lab-invoices', 'active', []); 

        case SubMenus.Vita_Living:
            return this.prepareObject(SubMenus.Vita_Living, 'fa fa-exchange', './vita-living', 'active', []);

        case SubMenus.Track_Progress:
            return this.prepareObject(SubMenus.Track_Progress, 'fa fa-exchange', './track-progress', 'active', []);

        case SubMenus.Supplement_Alerts:
            return this.prepareObject("Alerts", 'fa fa-exchange', './supplmnt-alerts', 'active', []);          

        case SubMenus.Appt_Timeliness:
              return this.prepareObject(SubMenus.Appt_Timeliness, 'fa fa-exchange', './appt-timeliness', 'active', []);
        case SubMenus.Office_Team:
              return this.prepareObject(SubMenus.Office_Team, 'fa fa-exchange', './office-teams', 'active', []);
        case SubMenus.Active_Providers:
              return this.prepareObject(SubMenus.Active_Providers, 'fa fa-newspaper-o', './provider', 'active', []);

        case SubMenus.Front_Office:
              return this.prepareObject(SubMenus.Front_Office, 'fa fa-newspaper-o', './Front_Office', 'active', []); 

        case SubMenus.Missing_Payments:
              return this.prepareObject(SubMenus.Missing_Payments, 'fa fa-newspaper-o', './missing-payments', 'active', []);
        case SubMenus.Provider_Invoices:
              return this.prepareObject(SubMenus.Provider_Invoices, 'fa fa-newspaper-o', './provider-transactions', 'active', []);

        case SubMenus.TimeSheet:
                return this.prepareObject(SubMenus.TimeSheet, 'fa fa-newspaper-o', './timesheets', 'active', []);

        case SubMenus.Payroll:
        return this.prepareObject(SubMenus.Payroll, 'fa fa-newspaper-o', './payroll', 'active', []);

        case SubMenus.Coupons_Used:
          return this.prepareObject(SubMenus.Coupons_Used,'fa fa-newspaper-o','./coupons-used','active',[]);
        
        case SubMenus.Brands:
          return this.prepareObject(SubMenus.Brands, 'fa fa-newspaper-o', './brands', 'active', []);
        
        case SubMenus.Topics_SubTopics:
          return this.prepareObject(SubMenus.Topics_SubTopics, '', './topics-subtopics', 'active', []);
        
        case SubMenus.KB:
          return this.prepareObject(SubMenus.KB, '', './kb-qna', 'active', []);  

        case SubMenus.KB_Entries:
          return this.prepareObject(SubMenus.KB_Entries, '', './kb-entries', 'active', []);
        
        case SubMenus.Checklists:
          return this.prepareObject(SubMenus.Checklists, '', './checklists', 'active', []);
        case SubMenus.Employee_Balances:
          return this.prepareObject(SubMenus.Employee_Balances, '', './employee-balances', 'active', []);
        case SubMenus.Lead_Metrics:
            return this.prepareObject(SubMenus.Lead_Metrics, '', './lead-metrics', 'active', []);
        case SubMenus.Employees:
            return this.prepareObject(SubMenus.Employees, '', './employeeList', 'active', []);
        case SubMenus.Time_Off:
              return this.prepareObject('Schedule Change/Time Off', 'fa fa-calendar-check-o', './schedule-change', 'active', []);
        case SubMenus.Staff_Dashboard:
          return this.prepareObject(SubMenus.Staff, 'fa fa-calendar-check-o', './staff-dashboard', 'active', []);
        case SubMenus.Providers_Pt_Access:
          return this.prepareObject(SubMenus.Providers_Pt_Access, '', './provider-access-control', 'active', []);
        case SubMenus.State_Registration:
          return this.prepareObject(SubMenus.State_Registration, '', './compliance/state-dashboard', 'active', []);
        case SubMenus.Waitlist_Patients:
          return this.prepareObject(SubMenus.Waitlist_Patients, '', './waitlist-patient', 'active', []);
        case SubMenus.Portal_Global_Settings:
          return this.prepareObject(SubMenus.Portal_Global_Settings, '', './portal-global-settings', 'active', []);
        case SubMenus.Staff:
            return this.prepareObject(SubMenus.Staff, 'fa fa-calendar-check-o', './staff', 'active', []);
        case SubMenus.KB_Read_Status:
            return this.prepareObject(SubMenus.KB_Read_Status, '', './kb-read-status', 'active', []);
        case SubMenus.Survey_Responses:
          return this.prepareObject(SubMenus.Survey_Responses, '', './survey-responses', 'active', []);
        case SubMenus.Surveys:
            return this.prepareObject(SubMenus.Surveys, '', './surveys', 'active', []);
        case SubMenus.Patient_Billing:
          return this.prepareObject(SubMenus.Patient_Billing, '', './patient-billing', 'active', []);
        case SubMenus.Recent_Payments:
            return this.prepareObject(SubMenus.Recent_Payments, '', './recent-payments', 'active', []);
        case SubMenus.Holidays:
          return this.prepareObject(SubMenus.Holidays,'','./holidays','active',[]);
        case SubMenus.Emails:
            return this.prepareObject(SubMenus.Emails, '', './email-config', 'active', []);
        case SubMenus.Provider_Schedules:
          return this.prepareObject(SubMenus.Provider_Schedules,'','./provider-schedules','active',[]);
        case SubMenus.Follow_Up_Patients:
          return this.prepareObject(SubMenus.Follow_Up_Patients,'','./follow-up-patients','active',[]);
        case SubMenus.Bank_Deposits:
          return this.prepareObject(SubMenus.Bank_Deposits,'','./bank-deposits','active',[]);
        case SubMenus.Product_Orders:
          return this.prepareObject(SubMenus.Product_Orders, '', './product-orders', 'active', []);
        case SubMenus.Phone_Logs:
          return this.prepareObject(SubMenus.Phone_Logs, '', './phone-logs', 'active', []);
        case SubMenus.Open_Orders:
          return this.prepareObject(SubMenus.Open_Orders, '', './open-orders', 'active', []);
        case SubMenus.Patient_Progress:
          return this.prepareObject(SubMenus.Patient_Progress, '', './patient-progress', 'active', []);
        case SubMenus.All_Emails:
          return this.prepareObject(SubMenus.All_Emails, '', './all-emails', 'active', []);  
        case SubMenus.My_Emails:
          return this.prepareObject(SubMenus.My_Emails, '', './emails', 'active', []);  
        case SubMenus.Patient_Health_Trends:
          return this.prepareObject(SubMenus.Patient_Health_Trends, '', './patient-health-trends', 'active', []);      
      }
  }
  private static prepareObject(name, cls, link, routerLinkActive, subMenus) {
    return {
      name,
      class:cls,
      link,
      routerLinkActive,
      subMenus
    };
  }

  public getAllPatientTabs() {
    let tabsData = TabServiceService.portalAccessControls
      .filter((access) => access.Role__c == 'Patient')[0]
      .Tabs_Access__c.split(';');
    
    // remove 'Patient' from tabs list
    tabsData.shift();

    tabsData = tabsData.map((tab) => {
      return tab.replace('Patient#', '');
    });

    let menus = [];
    let arrSubMenus = [];
    // prepare menus
    tabsData.forEach((tab: string) => {
      if (tab.includes('#')) {
        arrSubMenus.push(tab);
      } else if (PatientMenu[tab]) {
        menus.push(this.processPatientMenu(tab));
      }
    });

    menus.forEach((x, i) => {
      let res = arrSubMenus.filter((y) => y.includes(x.title + '#'));

      if (res.length > 0) {
        menus[i].expandable = true;
        res.forEach((r) => {
          const sm = r.substring(r.indexOf('#') + 1);
          if (PatientSubMenu[sm]) {
            x.subMenu.push(this.processPatientSubMenu(sm));
          }
        });
      }
    });

    return menus;
  }

  private processPatientMenu(tabName) {
    switch (tabName) {
      case PatientMenu.Dashboard:
        return {
          title: PatientMenu.Dashboard,
          expandable: false,
          icon: 'fa fa-tachometer',
          path: '/patientDashboard',
          subMenu: [],
        };
      case PatientMenu.Appointment:
        return {
          title: PatientMenu.Appointment,
          expandable: false,
          icon: 'fa fa-calendar',
          path: '/patientDashboard/appointment',
          subMenu: [],
        };
      case PatientMenu.Billing:
        return {
          title: PatientMenu.Billing,
          expandable: false,
          icon: 'fa fa-file-text',
          path: '/patientDashboard/billing',
          subMenu: [],
        };
      case PatientMenu.Emails:
        return {
          title: PatientMenu.Emails,
          expandable: false,
          icon: 'fa fa-envelope',
          path: '/patientDashboard/emails',
          subMenu: [],
        };
      case PatientMenu.Graphs:
        return {
          title: PatientMenu.Graphs,
          expandable: false,
          icon: 'fa fa-bar-chart',
          path: '/patientDashboard/graphs',
          subMenu: [],
        };
      case PatientMenu.Lab_Documents:
        return {
          title: PatientMenu.Lab_Documents,
          expandable: false,
          icon: 'fa fa-book',
          path: '/patientDashboard/lab-document',
          subMenu: [],
        };
      case PatientMenu.Life_Event:
        return {
          title: PatientMenu.Life_Event,
          expandable: false,
          icon: 'fa fa-camera',
          path: '/patientDashboard/life-event',
          subMenu: [],
        };
      case PatientMenu.Medications:
        return {
          title: PatientMenu.Medications,
          expandable: false,
          icon: 'fa fa-medkit',
          path: '/patientDashboard/medications',
          subMenu: [],
        };
      case PatientMenu.Messages:
        return {
          title: PatientMenu.Messages,
          expandable: false,
          icon: 'fa fa-comment',
          path: '/patientDashboard/messages',
          subMenu: [],
        };
      case PatientMenu.Marketing:
        return {
          title: PatientMenu.Marketing,
          expandable: false,
          icon: 'fa fa-newspaper-o',
          path: '/patientDashboard/marketing-event',
          subMenu: [],
        };
      case PatientMenu.Open_Orders:
        return {
          title: PatientMenu.Open_Orders,
          expandable: false,
          icon: 'fa fa-shopping-cart',
          path: '/patientDashboard/open-order',
          subMenu: [],
        };
      case PatientMenu.Patient_Forms:
        return {
          title: PatientMenu.Patient_Forms,
          expandable: false,
          icon: 'fa fa-address-card',
          path: '/patientDashboard/forms',
          subMenu: [],
        };
      case PatientMenu.Pharmacies:
        return {
          title: PatientMenu.Pharmacies,
          expandable: false,
          icon: 'fa fa-hospital-o',
          path: '/patientDashboard/pharmacy',
          subMenu: [],
        };
      case PatientMenu.Provider_Notes:
        return {
          title: PatientMenu.Provider_Notes,
          expandable: false,
          icon: 'fa fa-sticky-note',
          path: '/patientDashboard/provider-notes',
          subMenu: [],
        };
      case PatientMenu.Subscription:
        return {
          title: PatientMenu.Subscription,
          expandable: false,
          icon: 'fa fa-edit',
          path: '/patientDashboard/subscription',
          subMenu: [],
        };

      case PatientMenu.Supplements:
        return {
          title: PatientMenu.Supplements,
          expandable: false,
          icon: 'fa fa-medkit',
          path: '/patientDashboard/suppliments',
          subMenu: [],
        };

      case PatientMenu.Vaccine_Records:
        return {
          title: PatientMenu.Vaccine_Records,
          expandable: false,
          icon: 'fa fa-files-o',
          path: '/patientDashboard/vaccine',
          subMenu: [],
        };

      case PatientMenu.Vitals:
        return {
          title: PatientMenu.Vitals,
          expandable: false,
          icon: 'fa fa-heartbeat',
          path: '/patientDashboard/vitals',
          subMenu: [],
        };
    }
  }

  private processPatientSubMenu(tabName) {
    switch (tabName) {
      case PatientSubMenu.Patient_Matrix:
        return {
          title: PatientSubMenu.Patient_Matrix,
          path: '/patientDashboard/IMF',
        };
      case PatientSubMenu.Patient_Timeline:
        return {
          title: PatientSubMenu.Patient_Timeline,
          path: '/patientDashboard/life_event-graph',
        };
      case PatientSubMenu.Readiness_Assessment:
        return {
          title: PatientSubMenu.Readiness_Assessment,
          path: '/patientDashboard/readiness-assessment',
        };
      case PatientSubMenu.Symptom_Review:
        return {
          title: PatientSubMenu.Symptom_Review,
          path: '/patientDashboard/sympton-review',
        };
      case PatientSubMenu.Toxicity_Questionnaire:
        return {
          title: PatientSubMenu.Toxicity_Questionnaire,
          path: '/patientDashboard/toxicity-questinnaire',
        };
      case PatientSubMenu.Thyroid_Scores:
        return {
          title: PatientSubMenu.Thyroid_Scores,
          path: '/patientDashboard/thyroid-graph',
        };
    }
  }
}